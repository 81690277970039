<template>
  <div>
    <div class="zh-header">
      <van-row>
        <van-col span="14" @click="onClickBack" class="header-title">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          奖补详情
        </van-col>
        <!-- <van-col span="7" class="header-title" style="text-align: right"> -->
        <!-- </van-col> -->
        <van-col span="10" class="header-title" style="text-align: right">
          <div v-if="detailMessage.is_gong_shi" @click="toList">奖补名单</div>
          <div v-if="!detailMessage.is_gong_shi" @click="apply_jiangbu">
            申请
          </div>
        </van-col>
      </van-row>
    </div>
    <div
      style="height: 90vh; overflow-y: scroll; padding: 0 0.2rem 2rem 0.2rem"
    >
      <card style="margin-top: 30px">
        <template #content>
          <van-row style="text-align: center; line-height: 16px">
            <span class="title_text">{{ detailMessage.title }}</span>
          </van-row>
          <van-row style="margin-top: 30px" class="content_text">
            <span v-html="detailMessage.content"></span>
          </van-row>
          <van-row class="title_info_text">
            <van-col span="12" style="text-align: left">
              <span>{{ detailMessage.creator_name }}</span>
            </van-col>
            <van-col span="12" style="text-align: right">
              {{ detailMessage.created_at }}
            </van-col>
          </van-row>
        </template>
      </card>
      <!-- <div class="btn" @click="apply_jiangbu">申请</div> -->
    </div>
  </div>
</template>

<script>
// import {Toast} from "vant"
import api from "@/services/apis";

// import {mapGetters} from "vuex"
import card from "@/views/components/Card";
import { Toast } from "vant";
export default {
  name: "jiangbuInfo",
  components: {
    card,
  },
  data: () => ({
    tabIndex: 0,
    jiang_bu_id: null,
    detailMessage: {},
  }),
  computed: {},
  created() {
    this.jiang_bu_id = this.$route.query.id;
    if (this.jiang_bu_id) {
      this.getDetail();
    }
  },
  methods: {
    onClickBack() {
      this.$router.push({ path: "/message", query: { notification_type: 6 } });
    },
    getDetail() {
      api.get_jiangbu_mes(this.jiang_bu_id).then((res) => {
        if (res.status === 200) {
          this.detailMessage = res.data;
          console.log(this.detailMessage);
        }
      });
    },
    async apply_jiangbu() {
      const id = this.jiang_bu_id;
      const res = await api.apply_jiangbu(id);
      if (res.status === 200) {
        Toast("操作成功");
        setTimeout(() => {
          this.$router.go(-1);
        });
      }
    },
    toList() {
      this.$router.push({
        path: "/message/jiangbuList",
        query: { id: this.jiang_bu_id },
      });
    },
  },
};
</script>

<style scoped>
.zh-header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}

.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}

.title_text {
  font-size: 16px;
  font-weight: bold;
}

.title_info_text {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  color: #999999;
}

.content_text {
  color: #666666;
  font-size: 14px;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  /* margin-top:.4rem; */
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>